.operations-container {
  background-color: #f4f4f4;
  border-radius: 8px;
  padding: 16px;
  margin: 20px;
  font-family: Arial, sans-serif;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flag-button, .more-button {
  background-color: #e0e0e0;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.content p {
  margin: 10px 0;
}

.tags {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tag {
  background-color: #d1ecf1;
  border-radius: 4px;
  padding: 6px 12px;
  font-size: 0.9rem;
}

.tag.slack {
  background-color: #ffdeea; /* light pink */
}

.tag.date {
  background-color: #d4edda; /* light green */
}

.react-datepicker-popper {
  z-index: 1000 !important;
}

.leaflet-control-attribution {
  display: none ;
}
